import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import MenuSection from "../components/menu_section"
import SEO from "../components/seo"
import isMobile from "../utils/is_mobile"

const MenuPage = props => {
  const { data, path, location } = props
  const digitalQrMode = location?.search === "?digitalQr" && isMobile()

  const menu = data.prismicMenu.data

  return (
    <Layout path={path} digitalQrMode={digitalQrMode} location={location}>
      <SEO title="Menu" />
      <div className="section not-that-much-margin-on-top">
        <div className="image-section">
          <div className="image-container">
            <Img fluid={menu.menu_image.localFile.childImageSharp.fluid} />
          </div>
        </div>
        <div className="container">
          <h1 className="custom dark">{menu.title}</h1>
        </div>
      </div>
      {menu.sections.map(
        (section, index) =>
          section?.section?.document?.[0]?.data && (
            <MenuSection
              key={index}
              menu={section?.section?.document?.[0]?.data}
              inverted={index % 2 === 1}
            />
          )
      )}
      {/* <MenuSection menu={antipasti} inverted={false} /> */}
      <div className="accent">
        <div className="container flex last-menu-section">
          <div className="image-section">
            <div className="image-container">
              <div className="yet-another-container">
                <Img
                  fluid={menu.section_image.localFile.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
          <div className="description">
            <p className="title">{menu.section_title}</p>
            <p className="caption">{menu.section_description}</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MenuPage

export const query = graphql`
  query MenuQuery($localeRegex: String) {
    lead: file(relativePath: { eq: "home_head.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    head: file(relativePath: { eq: "home_head.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file(relativePath: { eq: "home_img1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img2: file(relativePath: { eq: "home_img2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img3: file(relativePath: { eq: "home_img3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img4: file(relativePath: { eq: "home_img4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prismicMenu(lang: { regex: $localeRegex }) {
      data {
        title
        sections {
          section {
            document {
              data {
                title
                dishes {
                  dish_name
                  dish_description
                  dish_price
                }
                images {
                  section_image {
                    localFile {
                      childImageSharp {
                        fluid(
                          maxWidth: 1000
                          quality: 95
                          pngCompressionSpeed: 1
                        ) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        section_title
        section_description
        menu_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

import Img from "gatsby-image"
import gsap from "gsap"
import React, { useEffect, useRef, useState } from "react"
import { withDimensions } from "./withDimensions"

const ImageClipper = withDimensions(props => {
  const clipper = useRef()
  const [current, setCurrent] = useState(props.currentFluid)
  const [next, setNext] = useState(props.nextFluid)
  const [rect, setRect] = useState({})

  useEffect(() => {
    setRect(clipper.current.getBoundingClientRect())
  }, [props.dimensions])

  useEffect(() => {
    //!DO ANIM
    // console.log(clipper, clipper.current)

    //? trigger onInterrupt
    if (gsap.isTweening(clipper.current)) {
      gsap.killTweensOf(clipper.current)
      //!TODO - delay next swap
    }

    // gsap.killTweensOf(clipper.current);

    gsap.fromTo(
      clipper.current,
      1,
      {
        clip: props.rtl
          ? `rect(0px,${rect.width}px,${rect.height}px,0px)`
          : `rect(0px,${rect.width}px,${rect.height}px,0px)`,
      },
      {
        delay: props.delay,
        clip: props.rtl
          ? `rect(0px,0px,${rect.height}px,0px)`
          : `rect(0px,${rect.width}px,${rect.height}px,${rect.width}px)`,
        clearProps: "clip",
        ease: "Power4.easeInOut",
        onComplete: () => {
          setCurrent(props.currentFluid)
          setNext(props.nextFluid)
        },
        onInterrupt: () => {
          setCurrent(props.currentFluid)
          setNext(props.nextFluid)
        },
      }
    )
  }, [props.currentFluid])

  return (
    <>
      <Img fluid={next} />
      <div
        ref={clipper}
        style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <Img fluid={current} />
      </div>
    </>
  )
})

const MenuSection = props => {
  const { inverted, menu, wineSection } = props

  // console.log("menu", menu, wineSection)

  const [selected, setSelected] = useState(0)
  const [timeToChange, setTimeToChange] = useState(false)

  const lineRef = useRef()

  let timer

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSelected((selected + 1) % images.length)
  //   }, 7000)
  //   return () => clearTimeout(timer)
  // }, [selected])

  // useEffect(() => {
  //   console.log("timeToChange", true)
  //   setTimeToChange(true)

  //   timer = setTimeout(() => {
  //     console.log("timeToChange", false)
  //     setTimeToChange(false)
  //   }, 6600)

  //   return () => clearTimeout(timer)
  // }, [selected])

  const nextIndex = difference => (selected + difference) % menu.images.length

  const sweepDuration = 4
  const idleDuration = 1

  useEffect(() => {
    gsap.killTweensOf(lineRef.current)
    gsap.to(lineRef.current, 0.5, {
      scaleX: 0,
      ease: "Power3.easeOut",
      onComplete: () => {
        gsap.to(lineRef.current, sweepDuration, {
          scaleX: 1,
          ease: "Power0.easeNone",
          delay: idleDuration,
          onComplete: () => setSelected(nextIndex(1)),
        })
      },
    })
  }, [selected])

  return (
    <div
      className={`menu-section container flex ${inverted ? "inverted" : ""}`}
      id={menu?.id ?? undefined}
    >
      {!wineSection && Boolean(menu?.images) && menu?.images?.length > 1 ? (
        <div className="gallery">
          <div className="flex">
            <div className="section small hoverable">
              <div className="image-container">
                <div
                  className="container visible"
                  onClick={() => setSelected(selected + 1)}
                >
                  {menu?.images?.[nextIndex(1)]?.section_image?.localFile
                    ?.childImageSharp?.fluid &&
                    menu?.images?.[nextIndex(2)]?.section_image?.localFile
                      ?.childImageSharp?.fluid && (
                      <ImageClipper
                        rtl={props.inverted}
                        currentFluid={
                          menu.images[nextIndex(1)].section_image.localFile
                            .childImageSharp.fluid
                        }
                        nextFluid={
                          menu.images[nextIndex(2)].section_image.localFile
                            .childImageSharp.fluid
                        }
                      />
                    )}
                  {/* <img src={images[nextIndex(1)]} /> */}
                </div>
                <div className="container hidden">
                  {/* <img src={images[nextIndex(2)]} /> */}
                </div>
              </div>
              <div className="image-indicator">
                <div className="progress-line" ref={lineRef} />
              </div>
            </div>
            <div className="section big">
              <div className="image-container">
                <div className="container visible">
                  {menu?.images?.[nextIndex(0)]?.section_image?.localFile
                    ?.childImageSharp?.fluid &&
                    menu?.images?.[nextIndex(1)]?.section_image?.localFile
                      ?.childImageSharp?.fluid && (
                      <ImageClipper
                        rtl={props.inverted}
                        delay={0.15}
                        currentFluid={
                          menu.images[nextIndex(0)].section_image.localFile
                            .childImageSharp.fluid
                        }
                        nextFluid={
                          menu.images[nextIndex(1)].section_image.localFile
                            .childImageSharp.fluid
                        }
                      />
                    )}
                  {/* <img src={images[selected]} /> */}
                </div>
                <div className="container hidden">
                  {/* <img src={images[nextIndex(1)]} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="wines-spacer" />
      )}
      <div className="menu">
        <p className={`menu-title ${wineSection ? "wine-section" : ""}`}>
          {menu.title}
        </p>
        <div className="menu-list">
          {menu.dishes.map((item, index) => (
            <div
              key={index}
              className={`menu-item ${item.spaced ? "spaced" : ""}`}
            >
              <div className="dish">
                <p className="title">{item.dish_name}</p>
                <p className="description padding-4">
                  {item.dish_description || ""}
                </p>
              </div>
              <span className="price">{item.dish_price}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MenuSection
